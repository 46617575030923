.grid {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.card {
  min-width: 15vw;
  margin: 0.5rem;
  flex-grow: 1;
  flex-basis: 0;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  text-align: center;
}

.card p {
  font-size: 1.25rem;
  line-height: 1.5;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}