.box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: right;
}

.Moderate {
    color: rgba(198, 119, 0, 0.81);
}

.Low {
    color: rgb(9, 136, 9);
}

.High {
    color: rgba(174, 1, 1, 0.837);
}